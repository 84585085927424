import {
  MagazineApiResponse,
  MagazineForm,
} from '@/domain/magazine/models/Magazine';
import { magazineSaveParam } from '@/domain/magazine/models/param/magazineParam';

export class MagazineConvertService {
  public convertApiResponseToForm(
    magazineApiResponse: MagazineApiResponse
  ): MagazineForm {
    return {
      id: magazineApiResponse.id,
      title: magazineApiResponse.title,
      catchphrase: magazineApiResponse.catchphrase,
      magazineContent: this.extractMagazineContent(
        magazineApiResponse.magazineContent
      ),
      status: magazineApiResponse.status,
      headerContents:
        magazineApiResponse.magazineBannerContents?.map(content => {
          return {
            contentId: content.id,
            url: content.thumbnail,
          };
        }) || [],
      publishStartDate: magazineApiResponse.publishStartDate,
    };
  }
  public convertFormToSaveParam(magazineForm: MagazineForm): magazineSaveParam {
    return {
      id: magazineForm.id,
      title: magazineForm.title,
      catchphrase: magazineForm.catchphrase,
      magazineContent: magazineForm.magazineContent,
      status: magazineForm.status,
      publishStartDate: magazineForm.publishStartDate
        ? new Date(magazineForm.publishStartDate)
        : null,
      magazineBannerContentIds: magazineForm.headerContents
        .filter(x => x.contentId)
        .map(x => parseInt(x.contentId)),
      magazineContentImageIds: magazineForm.magazineContentImageIds || [],
    };
  }

  private extractMagazineContent(htmlString: string): string {
    if (htmlString.includes('<div class="product-description">')) {
      const div = document.createElement('div');
      div.innerHTML = htmlString;
      const productDescription = div.querySelector('.product-description');
      return productDescription?.innerHTML || '';
    } else {
      return htmlString;
    }
  }
}
