import gql from 'graphql-tag';

export const MAGAZINE_QUERY = gql`
  query MagazineQuery($id: ID!, $width: Int!) {
    fripMagazine {
      magazine(id: $id) {
        id
        title
        catchphrase
        status
        magazineContent
        magazineBannerContents {
          id
          thumbnail(width: $width, fetchFormat: AUTO, quality: AUTO)
        }
        worker {
          id
          nickname
        }
        publishStartDate
      }
    }
  }
`;

export const MAGAZINES_QUERY = gql`
  query MagazinesQuery(
    $page: Int
    $size: Int
    $orderType: MagazineOrderType
    $filter: MagazineFilter
  ) {
    fripMagazine {
      magazines(
        page: $page
        size: $size
        orderType: $orderType
        filter: $filter
      ) {
        totalCount
        edges {
          cursor
          node {
            id
            title
            catchphrase
            status
            magazineContent
            worker {
              id
              nickname
            }
            publishStartDate
          }
        }
        pageInfo {
          endCursor
        }
      }
    }
  }
`;
