









































































import Vue from 'vue';
import ImageUploadContainer from '@/components/ImageUpload/ImageUploadBoxWithImageObject.vue';
import FroalaEditor from '@/components/FroalaEditor.vue';
import { CommonState } from '@frientrip/domain';
import DateInput from '@/components/Forms/DateInput.vue';

interface Image {
  contentId: string;
  url: string;
}

export default Vue.extend({
  name: 'MagazineContentForm',
  components: {
    ImageUploadContainer,
    FroalaEditor,
    DateInput,
  },
  props: {
    magazine: {
      type: Object,
    },
    isUpdateForm: {
      type: Boolean,
    },
  },
  data() {
    return {
      date: '',
      form: this.magazine,
      magazineStatusOptions: [
        { value: CommonState.ACTIVE, text: 'OPEN' },
        { value: CommonState.INACTIVE, text: 'SEMI-OPEN' },
      ],
      contentIds: [] as string[],
      wordsOfGuidance:
        '텍스트를 사용한 대표 이미지는 노출이 불가합니다. 우측 가이드를 반드시 준수하시어 대표 이미지를 등록해주세요.',
    };
  },
  computed: {
    instant(): number {
      const date = this.$moment(this.date);
      return (
        date.clone().endOf('day').subtract('1', 'second').valueOf() ||
        new Date().getTime()
      );
    },
  },
  watch: {
    magazine: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.form = newValue;
      },
    },
  },
  methods: {
    clearContent() {
      this.form.magazineContent = '';
    },
    onInput() {
      this.$emit('input', this.instant);
    },
    saveImages(image: Image) {
      Vue.set({ magazine: this.form }, 'headerContents', [
        {
          contentId: image.contentId,
          url: image.url,
        },
      ]);
      this.saveForm();
    },
    addContentId(contentId: string) {
      this.form.magazineContentImageIds.push(parseInt(contentId));
    },
    saveForm() {
      this.$emit('save', this.form);
    },
  },
});
