










































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue, { VueConstructor } from 'vue';
import { imageUploadService } from './imageUploadService';
import Spinner from '@/components/Spinner.vue';

// cloudinary에서 받은 이미지 정보
interface ImageAPIResponse {
  width: number;
  height: number;
  bytes: number;
  url: string;
}

const bytesToMegaBytes = (byte: number) => {
  return byte / 1048576;
};

// 참고: https://github.com/fabiofranchino/vue-drop-image-and-preview
export default (
  Vue as VueConstructor<Vue & { $refs: { [key: string]: HTMLElement } }>
).extend({
  name: 'ImageUploadBox',
  components: {
    Spinner,
  },
  props: {
    title: {
      type: String,
    },

    description: {
      type: String,
    },

    width: {
      type: Number,
    },

    height: {
      type: Number,
    },

    maximumFileSize: {
      type: Number,
    },

    defaultImage: {
      type: Object,
    },
  },
  data() {
    return {
      isDragging: false,
      imageName: '',
      loading: false,
      preview: this.defaultImage ? this.defaultImage.url : '',
      contentId: this.defaultImage ? this.defaultImage.contentId : '',
      image: '',
      editBtn: false,
    };
  },
  computed: {
    imgBoxClass(): string {
      return this.isDragging ? 'drop' : 'imgInputBox';
    },
  },
  methods: {
    showEditBtn() {
      if (this.preview) {
        this.editBtn = true;
      }
    },

    disableEditBtn() {
      this.editBtn = false;
    },
    // 화면 효과(드래그로 이미지 넣을때)
    dragOver() {
      this.isDragging = true;
    },

    dragLeave() {
      this.isDragging = false;
    },

    validateImage(image: ImageAPIResponse) {
      if (this.width && image.width !== this.width)
        return alert('이미지 width가 맞지 않습니다.');

      if (this.height && image.height !== this.height)
        return alert('이미지 height가 맞지 않습니다.');

      if (
        this.maximumFileSize &&
        bytesToMegaBytes(image.bytes) > this.maximumFileSize
      )
        return alert('이미지 용량이 너무 큽니다');

      this.preview = image.url;
    },

    async uploadImage(imageFile: File) {
      this.preview = '';
      if (!imageFile.type.match('image/.*')) {
        return alert('이미지만 업로드 가능합니다.');
      }
      this.loading = true;
      const { image, contentId } = await imageUploadService.uploadImage(
        imageFile
      );
      this.loading = false;
      this.validateImage(image);

      if (this.preview === image.url) {
        this.$emit('input', { url: image.url, contentId: contentId });
      }
    },

    async drop(event: any) {
      this.isDragging = false;
      const file = event.dataTransfer.files[0];
      await this.uploadImage(file);
    },

    onClickUploadImage() {
      console.log('upload image!');
      this.$refs.input.click();
    },

    async getImage(event: any) {
      const file = event.target.files[0];
      console.log('get Image!');
      await this.uploadImage(file);
      (this as any).$refs.input.value = '';
    },
    deleteImage() {
      this.image = '';
      this.preview = '';
      (this as any).$refs.input.value = '';
      this.$emit('input', { url: '', contentId: '' });
    },
  },
});
