










































import Vue from 'vue';

export default Vue.extend({
  name: 'DateInput',
  props: {
    label: {
      type: String,
    },
    value: {
      type: Number,
    },
    required: {
      type: Boolean,
      default: false,
    },
    question: {
      type: String,
    },
    dateLabel: {
      type: String,
    },
    type: {
      type: String,
      default: 'datetime-local',
    },
    labelColsLg: {
      type: String,
      default: '2',
    },
    contentColsLg: {
      type: String,
      default: '6',
    },
    simpleTerm: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: '',
      formatType: this.type === 'date' ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm:ss',
    };
  },

  computed: {
    labelCols(): string {
      if (this.label) {
        return this.labelColsLg;
      } else {
        return '';
      }
    },
    paddingClass(): string {
      if (this.block) {
        return 'py-1 px-3';
      } else {
        return 'py-1';
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      handler: function (date: number | null) {
        if (date) {
          this.date = this.$moment(date).format(this.formatType);
        } else {
          this.date = '';
        }
      },
    },
  },
  created() {
    if (this.type === 'datetime-local') {
      this.formatType = 'YYYY-MM-DDTHH:mm:ss'; // datetime-local
    }
  },
  methods: {
    setInstant(): void {
      let date = new Date(this.date).getTime();
      if (this.type === 'date') {
        date = this.$moment(this.date).startOf('day').valueOf();
      }

      this.$emit('input', date);
    },
  },
});
