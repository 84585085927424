





























import Vue from 'vue';
import { apolloClient } from '@/apolloClient';
import { ApolloQueryResult } from 'apollo-client';
import { CommonState } from '@frientrip/domain';
import { MAGAZINE_QUERY } from '@/domain/magazine/queries/query';
import MagazineContentForm from '@/domain/magazine/components/MagazineContentForm.vue';
import { MagazineSaveService } from '@/domain/magazine/service/MagazineSaveService';
import {
  MagazineApiResponse,
  MagazineForm,
} from '@/domain/magazine/models/Magazine';
import { MagazineConvertService } from '@/domain/magazine/service/MagazineConvertService';
import { magazineQueryParam } from '@/domain/magazine/models/param/magazineParam';
import { isHostAdmin } from '@/env';

const magazineSaveService = new MagazineSaveService(apolloClient);
const magazineConvertService = new MagazineConvertService();
export default Vue.extend({
  name: 'MagazineSaveContainer',
  components: {
    MagazineContentForm,
  },
  data() {
    return {
      loading: false,
      isHostAdmin: isHostAdmin(),
      id: this.$route.params.id || '',
      isCopy: false,
      magazineLoading: 0,
      host: {
        id: this.$store.state.userToken.hostId || '',
      },
      form: magazineSaveService.getMagazineInitForm(),
    };
  },
  computed: {
    isUpdate(): boolean {
      return this.id !== '' && this.$route.path !== '/magazine/create';
    },
    renderForm(): boolean {
      if (!this.isUpdate) return true;
      else if (this.magazineLoading === 0 && this.form.id) {
        return true;
      } else {
        return false;
      }
    },
    formTitle(): string {
      return this.isUpdate ? '매거진 수정' : '매거진 등록';
    },
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler(newValue: string) {
        if (newValue === '/magazine/create') {
          this.id = '';
        }
      },
    },
  },
  methods: {
    clearContent() {
      return '';
    },
    redirectToMagazineListPage() {
      this.$router.push('/magazine/list');
    },
    async saveMagazine() {
      this.loading = true;
      try {
        await magazineSaveService.saveMagazine(
          magazineConvertService.convertFormToSaveParam(this.form)
        );

        this.$modal.show(
          {
            title: '저장되었습니다.',
            type: 'primary',
            showCancelButton: true,
            confirmText: '확인',
          },
          () => {
            this.$router.push('/magazine/list');
          }
        );
      } catch (e) {
        this.$notify({
          title: '매거진 저장 실패',
          message: e.message,
          type: 'warning',
        });
      }
      this.loading = false;
    },

    async deleteMagazine(): Promise<void> {
      this.$modal.show(
        {
          title: '매거진을 삭제하시겠습니까?',
          type: 'primary',
          showCancelButton: true,
          confirmText: '네',
          cancelText: '아니오',
        },
        async () => {
          this.loading = true;
          try {
            await magazineSaveService.saveMagazine(
              magazineConvertService.convertFormToSaveParam({
                ...this.form,
                status: CommonState.DELETED,
              })
            );

            this.$router.push('/magazine/list');

            this.$modal.show({
              title: '매거진 삭제 완료',
              message: '매거진 삭제를 완료했습니다!',
              type: 'success',
            });
          } catch (e) {
            this.$notify({
              title: '매거진 삭제 실패',
              message: e.message,
              type: 'warning',
            });
          }
          this.loading = false;
        }
      );
    },
  },
  apollo: {
    form: {
      query: MAGAZINE_QUERY,
      loadingKey: 'magazineLoading',
      variables(): magazineQueryParam {
        return {
          id: this.id.toString(),
          width: 400,
        };
      },
      skip(): boolean {
        return this.$route.path === '/magazine/create';
      },
      result(
        result: ApolloQueryResult<{
          fripMagazine: { magazine: MagazineApiResponse };
        }>
      ) {
        this.form = magazineConvertService.convertApiResponseToForm(
          result.data.fripMagazine.magazine
        );
      },
      update(data: {
        fripMagazine: { magazine: MagazineApiResponse };
      }): MagazineForm {
        const apiResponse = data.fripMagazine.magazine;

        return magazineConvertService.convertApiResponseToForm(apiResponse);
      },
    },
  },
});
