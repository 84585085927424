import { ApolloClient } from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { SAVE_MAGAZINE_MUTATION } from '@/domain/magazine/queries/mutation';
import { CommonState } from '@frientrip/domain';
import { magazineSaveParam } from '@/domain/magazine/models/param/magazineParam';
import { MagazineForm } from '@/domain/magazine/models/Magazine';

export class MagazineSaveService {
  constructor(private readonly apollo: ApolloClient<NormalizedCacheObject>) {}

  async saveMagazine(param: magazineSaveParam) {
    if (
      param.magazineContent &&
      !param.magazineContent.includes('<div class="product-description">')
    ) {
      param.magazineContent = this.wrapProductDescriptionTag(
        param.magazineContent
      );
    }

    if (
      param.magazineContent &&
      !param.magazineContent.includes('<style type="text/css">')
    ) {
      param.magazineContent = this.addProductDescriptionCss(
        param.magazineContent
      );
    }

    const response = await this.apollo.mutate({
      mutation: SAVE_MAGAZINE_MUTATION,
      variables: {
        param: {
          id: param.id,
          title: param.title,
          catchphrase: param.catchphrase,
          magazineContent: param.magazineContent,
          status: param.status,
          publishStartDate: new Date(param.publishStartDate || ''),
          magazineBannerContentIds: param.magazineBannerContentIds,
          magazineContentImageIds: param.magazineContentImageIds,
        },
      },
    });

    return response.data.saveMagazine;
  }

  public getMagazineInitForm(): MagazineForm {
    return {
      title: '',
      catchphrase: '',
      magazineContent: '',
      status: CommonState.INACTIVE,
      headerContents: [],
      magazineContentImageIds: [],
    };
  }

  /**
   * html 요소를 'product-description' 라는 class 를 가진 div element 로 wrapping 시킵니다.
   * product-description 은
   *  - 요소 내부 자체 가로 여백 padding 20px
   *  - li 태그 style override
   *  - p 태그 margin 초기화.
   *
   * @param htmlContent
   * @return {string}
   */
  private wrapProductDescriptionTag(htmlContent: string) {
    return `<div class="product-description">${htmlContent}</div>`;
  }

  private addProductDescriptionCss(htmlContent: string) {
    return `<style type="text/css">
                .product-description {
                    padding: 0 20px;
                    margin: 0;
                }
                .product-description img {
                    width: calc(100% + 40px);
                    max-width: calc(100% + 40px);
                    margin-left: -20px;

                }
                .product-description li {
                    list-style-position: inside;
                }
                .product-description p {
                    margin: 0;
                }
            </style>${htmlContent}`;
  }
}
